<template>
  <div id="MapaEdicao" >
		<div class="d-flex" >
			<div style="width: 150px; height: 80vh;">
				<form class="form-inline" style="font-size:15px">
					<label class="ml-2"><strong>Tipo:</strong> &nbsp;</label><br>
					<select id="pol_type">
						<option value="Mapa">Mapa</option>
						<option value="Polygon">Polygon</option>
						<option value="Retangulo">Retangulo</option>
						<option value="Imagem">Imagem</option>
						<!-- <option value="Point">Point</option> -->
						<!-- <option value="LineString">LineString</option> -->
						<!-- <option value="Circle">Circle</option> -->
					</select>

					<select id="pol_sel" v-show="false" class="form-control">
						<option value="click" selected>Click</option>
						<option value="singleclick">Single-click</option>
						<option value="pointermove">Hover</option>
						<option value="altclick">Alt+Click</option>
						<option value="none">None</option>
					</select>

					<label class="ml-2">
						<strong>
							Unidade:
						</strong> &nbsp;
					</label>

					<v-list class="pa-0" style="overflow-y: scroll; height: 70vh;">
						<v-list-item-group
							color="primary"
							v-model="vm_quadra_selected"
							return-object
							mandatory>
							<v-list-item
								class="pl-0"
								v-for="(option, index) in vm_quadra_options"
								:key="index"
								:value="{index: option.index, cod_imovel: option.cod_imovel, quadra_lote: option.quadra_lote, quadra: option.quadra, lote: option.lote}">
								<v-list-item-icon>
									<v-icon >{{ option.icon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title >{{ option.quadra }} / {{ option.lote }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
      	</form>
			</div>
			<div ref="map" id="map" v-bind:style="{ left:'0px', width: '100vw', height: getHeight()-30+'px' } " >
				<div ref="map2" id="map2" >
				</div>
			</div>
		</div>

    <v-overlay :value="overlay">
				<v-progress-circular
				    class="align-center pa-5"
        			indeterminate
	        		size="180"
					width="7"

	    		>
				<h2>Quadra: {{vm_quadra_selected?.quadra}} Lote: {{vm_quadra_selected?.lote}}</h2>
				</v-progress-circular>
    </v-overlay>

  </div>
</template>

<script type="text/javascript">

//import Vue from "vue";

import {API, 
		API_BLOB,
		BASE_URL_WEBSOCKET,
       } from "../../services/API";

//import Loader from "components/Loader";

import "./MapaEmpreendimentos.css";
//import "../ModalNegociacao/ModalNegociacao.css";

// import { socket } from "../../main";
import io from 'socket.io-client';

// -- Mapa -----------------------------------------------------------------------------------
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
//import OSM from 'ol/source/OSM'
import BingMaps from "ol/source/BingMaps.js";
import GeoJSON from "ol/format/GeoJSON";
import OlImageLayer from "ol/layer/Image";
import ImageStatic from "ol/source/ImageStatic";
import Static from 'ol/source/ImageStatic';
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { defaults as DefaultControls, FullScreen } from "ol/control";
import { DragRotateAndZoom, defaults as defaultInteractions } from "ol/interaction";
import { Draw, Modify, Snap } from 'ol/interaction.js';
import { createBox, createRegularPolygon } from 'ol/interaction/Draw';
import { getCenter } from 'ol/extent';
import Polygon from 'ol/geom/Polygon';
import MultiPoint from 'ol/geom/MultiPoint';
import { Fill, Style, Stroke } from "ol/style.js";
import { fromLonLat, transformExtent } from "ol/proj.js";
import Select from "ol/interaction/Select.js";
import { click, pointerMove, altKeyOnly } from 'ol/events/condition.js';
import Feature from 'ol/Feature';
import { rotateProjection } from "./Mapa_Funcoes"

import * as html2canvas from 'html2canvas';

var socket;

var style_selected = new Style({ //style to be added on selected layer
	fill: new Fill({
		color: "rgba(255, 0, 0, 0.5)",
		opacity: 0.5
	}),
	stroke: new Stroke({
		color: 'red',
		width: 3,
		opacity:1
	})
})
// var select = new Select({
//      style: style_selected,
// });

export default {
	props:[
		'cod_empreendimento'
	],
	components: {
	},
	data() {
		return {
			result: { },
			map  : null,
			map2 : null,
			geo_JSON : null,
			pol_source1 : null,
			pol_vector1 : null,
			pol_source2 : null,
			pol_vector2 : null,
			id     : null,
			mapEmp : {},
			ImgMapa : {},
			ImgMapaThumb : {},
			ls_CodEmpreendimento 	: "",
			overlay					: false,

			// Selecoes ----------------------------------
			pol_select: null,
			pol_selectSingleClick: null,
			pol_selectClick: null,
			pol_selectPointerMove: null,
			pol_selectAltClick: null,
			pol_selectElement: null,
			pol_changeInteraction: null,
  			pol_draw: null,
  			pol_snap: null,
  			pol_typeSelect: null,

			imageBounds: {
				bottom: null,
				left: null,
				top: null,
				right: null,
			},			
			img_opacity: 1,
			imageSize: {
				width: null,
				height: null,
			},
			lat: null,
			lng: null,
			zoom: null,
			rotacao: null,
			pol_lote: 0,
			imagem_rotacao: 0,

			// Seleção de Quadra e Lote ------------
			vm_quadra_selected: {},
			vm_quadra_options: [],
          	imoveis 		: null,
          	imoveisSelected : null,

			grava_poligono : this.grava_Poligono,

			itemSelecionado : null

		}

	},

	beforeDestroy() {
		socket.disconnect();
	},
	 
 	async created () {
 	},

	beforeMount() {
		this.id = this.$route.query.cod_empreendimento || this.cod_empreendimento;
		//this.id = 15; // 15=Agnello-Veredas | 2=Tegeplan
	},

	watch: {
		vm_quadra_selected() {
			this.$nextTick(this.scrollToSelectedItem);
		}
	},

	async mounted () {
		var __this = this;

		// Socket -------------------------------------------
		socket = io(BASE_URL_WEBSOCKET, {  transports          : ['websocket'],
											secure              : false,
											reconnection        : true,
											rejectUnauthorized  : false,
											forceNew            : true
										}
						);
		socket.on("connected", function(e) {
			//console.log("ON CONNECTED: ", __this.id);
			socket.emit("sala_mapa", __this.id);
		});
		socket.on("connect", function(e) {
			//console.log("ON CONNECT: ", __this.id);
			socket.emit("sala_mapa", __this.id);
		});
		socket.on("connection", function(e) {
			//console.log("ON CONNECTION: ", __this.id);
			socket.emit("sala_mapa", __this.id);
		});
		socket.on('reconnect', function(e) {
			//console.log("ON RECONNECT: ", __this.id);
  			socket.emit("sala_mapa", __this.id);
		})
		// Callback de resposta de atualizações do Mapa -----
		socket.on("mapa", function(data)  {
			if (JSON.parse(data).mapa) {
				//console.log("mapa atualizou:---------------- ", JSON.parse(data).mapa);
				__this.atualizaPoligonos(JSON.parse(data).mapa);
			}
		});

		// Busca Quadras e Lotes do Empreendimento -------------------------------
        let res_quadra_lote = await this.quadras_lotes(this.id);
		if (res_quadra_lote) {
			let index = 0;
			let ls_array_mun = res_quadra_lote.data.result.map((elem) => ({
				cod_imovel  : elem.cod_imovel,
				quadra_lote : elem.quadra + ' / ' + elem.lote,
				quadra      : elem.quadra,
				lote        : elem.lote,
				index       : index++
				//cor			: "#FF0000"
			}));
			this.vm_quadra_options  = ls_array_mun;
			this.vm_quadra_selected = this.vm_quadra_options[0];
		}

		// Busca Dados do Empreendimento -------------------------------
		let resultado = await this.ByEmpreendimento(this.id);
		if (resultado) {
			this.mapEmp = resultado.data.result;

			///+++ Quando for Lazarus
			this.imageBounds = {
				bottom: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lng), // lat
				left: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lat), // lng
				top: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lng), // lat
				right: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lat), // lng
			},
			///+++ Quando for NodeJS, lng e lat invertido
			// this.imageBounds = {
			// 	left: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lng), // lat
			// 	bottom: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lat), // lng
			// 	right: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lng), // lat
			// 	top: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lat), // lng
			// },

			this.img_opacity = 1,
			this.imageSize = {
				width: parseFloat(this.mapEmp.empreend_mapa_width),
				height: parseFloat(this.mapEmp.empreend_mapa_height),
			},
			
			///+++ Quando for Lazarus
			this.lat = parseFloat(this.mapEmp.empreend_mapa_longitude),
			this.lng = parseFloat(this.mapEmp.empreend_mapa_latitude),
			///+++ Quando for NodeJS, lng e lat invertido
			// this.lng = parseFloat(this.mapEmp.empreend_mapa_longitude),
			// this.lat = parseFloat(this.mapEmp.empreend_mapa_latitude),

			this.zoom = parseFloat(this.mapEmp.empreend_mapa_zoom),
			this.rotacao = parseFloat(this.mapEmp.empreend_mapa_rotacao),
			this.pol_lote = 0,
			this.imagem_rotacao = parseFloat(this.mapEmp.empreend_imagem_rotacao)
		}
		// -- Bing Layer -------------------------------------------------------------
		this.layer_Bing = new TileLayer({
			visible: true,
			preload: Infinity,
			source: new BingMaps({
				key: "AjqLNOOSzrQfPcNyK5safWtF7DI41xjmTF4Q7jUWyAAd-rX-Zu2Cn2HptKEJIte8",
				imagerySet: "AerialWithLabels",
				maxZoom: 19,
			}),
		});
		// -- Bing Layer -------------------------------------------------------------
		this.layer_Bing2 = new TileLayer({
			visible: true,
			preload: Infinity,
			source: new BingMaps({
				key: "AjqLNOOSzrQfPcNyK5safWtF7DI41xjmTF4Q7jUWyAAd-rX-Zu2Cn2HptKEJIte8",
				imagerySet: "AerialWithLabels",
				maxZoom: 19,
			}),
		});

		// -- Mapa --------------------------------------------------------------------
 		this.map = new Map({
 			controls: DefaultControls().extend([new FullScreen()]),
 			interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
 			target: this.$refs['map'],
 			view: new View({
				zoom: this.zoom,
				center: fromLonLat([this.lng, this.lat]),
 			}),
 			layers: [this.layer_Bing],
		});
		// -- Mapa Auxiliar ------------------------------------------------------------
		this.map2 = new Map({
			controls: [],
			interactions: [],
			target: this.$refs['map2'],
			view: new View({
				zoom: this.zoom,
				center: fromLonLat([this.lng, this.lat]),
			}),
			layers: [this.layer_Bing2],
			//Fernando, 2023-07-13, retirar o layer do Bing para criar imagem sem o fundo de satelite
			//layers: [],
		});	  

		// -- Loteamento -------------------------------------------------------------
		resultado = await this.MapaImagemByEmpreendimento(this.id);
		if (resultado) {
			const projection = this.map.getView().getProjection();
			let imageExtent = this.transform([
			this.imageBounds.left,
			this.imageBounds.bottom,
			this.imageBounds.right,
			this.imageBounds.top,
			]);
			imageExtent = transformExtent(imageExtent, "EPSG:3857", projection);
			this.imageSource = new Static({
			url: resultado,
			crossOrigin: "",
			projection: rotateProjection(
				"EPSG:3857",
				(Math.PI / 2) * this.imagem_rotacao,
				imageExtent
			),
			imageExtent: imageExtent,
			imageSmoothing: true,
			opacity: 0.5,
			});

			this.ImgMapa = new OlImageLayer({
				opacity: 1,
				// source: new ImageStatic({
				//   imageSize: [this.imageSize.width, this.imageSize.height],
				//   url: resultado,
				//   projection: "EPSG:3857", // pixel
				//   imageExtent: this.transform([
				//     this.imageBounds.left,
				//     this.imageBounds.bottom,
				//     this.imageBounds.right,
				//     this.imageBounds.top,
				//   ]),
				// }),
			});
			this.ImgMapa.setSource(this.imageSource);
			this.map.addLayer(this.ImgMapa);

			this.ImgMapaThumb = new OlImageLayer({
				opacity: 1,
				// source: new ImageStatic({
				//   imageSize: [this.imageSize.width, this.imageSize.height],
				//   url: resultado,
				//   projection: "EPSG:3857", // pixel
				//   imageExtent: this.transform([
				//     this.imageBounds.left,
				//     this.imageBounds.bottom,
				//     this.imageBounds.right,
				//     this.imageBounds.top,
				//   ]),
				// }),
			});
			this.ImgMapaThumb.setSource(this.imageSource);
			this.map2.addLayer(this.ImgMapaThumb);

			// Poligonos ----------------------------------------------------------------
			this.geo_JSON = {};
			this.pol_source1 = new VectorSource({
			projection: "EPSG:3857",
			format: new GeoJSON({}),
			});
			this.pol_vector1 = new VectorLayer({
			source: this.pol_source1,
			style: this.drawStyle,
			});

			this.map.addLayer(this.pol_vector1);
		}

		this.atualizaPoligonosDB(this.id);	

		// Poligonos que poderão ser desenhados ------
		this.pol_modify = new Modify({ source: this.pol_source1 });
		this.map.addInteraction(this.pol_modify);
		this.pol_typeSelect = document.getElementById('pol_type');
		this.pol_typeSelect.addEventListener('change', () => {
			this.map.removeInteraction(this.pol_draw);
			this.map.removeInteraction(this.pol_snap);
			this.map.removeInteraction(this.pol_modify);
			this.map.removeInteraction(this.pol_select);
			if (this.pol_typeSelect.value === 'Mapa' || this.pol_typeSelect.value === 'Imagem') {
				this.sel_addInteractions();
			} else {
				this.pol_addInteractions();
			}
		});

		// Selecoes --------------------------------------------------
		this.pol_select = null; // ref to currently selected interaction
		this.pol_selectSingleClick = new Select();
		this.pol_selectClick = new Select({
			condition: click
		});
		this.pol_selectPointerMove = new Select({
		condition: pointerMove
			});
		this.pol_selectAltClick = new Select({
			condition: function (mapBrowserEvent) {
				return click(mapBrowserEvent) && altKeyOnly(mapBrowserEvent);
			}
		});
		this.pol_selectElement = document.getElementById('pol_sel');
		this.pol_selectElement.addEventListener('change', () => {
			this.map.removeInteraction(this.pol_draw);
			this.map.removeInteraction(this.pol_snap);
			this.map.removeInteraction(this.pol_modify);
			this.map.removeInteraction(this.pol_select);
			if (this.pol_typeSelect.value === 'Mapa' || this.pol_typeSelect.value === 'Imagem') {
				this.sel_addInteractions();
			} else {
				this.pol_addInteractions();
			}
		});

		// Setando seleção no Mapa
		this.map.removeInteraction(this.pol_draw);
		this.map.removeInteraction(this.pol_snap);
		this.map.removeInteraction(this.pol_modify);
		this.map.removeInteraction(this.pol_select);
		this.sel_addInteractions();

		// -------------------------------------------------------------------------------

		this.map.getView().setRotation(this.rotacao);
		this.map2.getView().setRotation(this.rotacao);
		await this.addEventListener();
		
	},

	methods : {
		async addEventListener() {
			var _this = this;

			window.addEventListener("resize", function (e) {
				if (e) {
					e.stopImmediatePropagation();
					clearTimeout(window.updateSizeTimer);
					window.updateSizeTimer = setTimeout(async function () {
						var orgSize = _this.map.getSize();
						if (orgSize) {
							var newSize = [orgSize[0] + 100, await _this.getHeight() + 100];
							_this.map.setSize(newSize);
							_this.$refs['map'].style.height = await _this.getHeight() + "px";
							const view = await _this.map.getView();

							view.calculateExtent(_this.map.getSize());
							//map.getView().fit(extent, {size: newSize});
							//console.log(map.getTargetElement().style.height);
							//const li_Height = getHeight() + "px";
							//map.getTargetElement().style.height = li_Height;
							//map.setSize([map.getTargetElement().style.width + "px", li_Height]);
							//map.setSize([300, 400]);
						}
					}, 500);
				}
			})
		},

		async ByEmpreendimento(cod_empreendimento) {
			const resposta = await API.get(`site/mapa_empreendimento`, {
				params: {
					cod_empreendimento : cod_empreendimento,
				},
			});
			if (resposta) {
				return resposta;
			}
		},

		async MapaByEmpreendimento(cod_empreendimento) {
			const resposta = await API.get(`site/mapa_json`, {
				params: {
					cod_empreendimento : cod_empreendimento,
				},
			});
			if (resposta) {
				return resposta;
			}
		},

		async MapaImagemByEmpreendimento(cod_empreendimento) {
			const resposta = await API_BLOB.get(`site/mapa_imagem`, {
				params: {
					cod_empreendimento : cod_empreendimento,
				},
			});
			if (resposta) {
				const file = new Blob([resposta.data], { type: "image/png" });
				const url = URL.createObjectURL(file);
				return url;
			}
		},

		async quadras_lotes(cod_empreendimento) {
			const resposta = await API.get(`site/quadras_lotes`, {
				params: { 
					cod_empreendimento : cod_empreendimento
				},
			});
			if (resposta) {

				if (resposta.status == 200) {
					this.imoveis = resposta.data.result;
					this.imoveisSelected = this.imoveis[0].cod_imovel;
				}
				return resposta;
			}
		},

		async mapa_grava_imovel_poligono(Obj) {
			const res_grava_mapa = await API.post(`site/mapa_grava_imovel_poligono`, 
			                                       Obj
												  )
			if (res_grava_mapa) {
				return res_grava_mapa
			}
		},

		drawStyle(feature) {
			var color = feature.values_.cor;
			if (!color) 
				color = "#FF0000"
			return [
				new Style({
					fill: new Fill({
						color: this.rgba(color),
					}),
				}),
			];
		},

		async atualizaPoligonosDB() {
			const resultado = await this.MapaByEmpreendimento(this.id)
			if (resultado) {
				this.geo_JSON = resultado.data.result;
				if (this.pol_vector1) {
				  await this.map.removeLayer(this.pol_vector1);
				}

				this.pol_source1 = new VectorSource({
					projection: "EPSG:3857",
					features: new GeoJSON().readFeatures(this.geo_JSON, {
						featureProjection: "EPSG:3857",
					}),
					format: new GeoJSON({}),
				});
				this.pol_vector1 = new VectorLayer({
					source: this.pol_source1,
					style: this.drawStyle,
				});
			}
			await this.map.addLayer(this.pol_vector1);
		},

		async atualizaPoligonos(data) {
  			var _this = this;
    		_this.geo_JSON = data;
			_this.pol_source2 = new VectorSource({
				projection: "EPSG:3857",
				features: new GeoJSON().readFeatures(_this.geo_JSON, {
				featureProjection: "EPSG:3857",
				}),
				format: new GeoJSON({}),
			});
			_this.pol_vector2 = new VectorLayer({
				source: _this.pol_source2,
				style: _this.drawStyle,
			});

			if (_this.pol_vector1) {
			  await _this.map.removeLayer(_this.pol_vector1);
			}
			await _this.map.addLayer(_this.pol_vector2);
			_this.pol_source1 = _this.pol_source2;
			_this.pol_vector1 = _this.pol_vector2;
			_this.pol_vector2 = {};
			_this.pol_source2 = {};
			if (_this.pol_typeSelect.value === 'Polygon' || _this.pol_typeSelect.value === 'Retangulo') {
				_this.pol_addInteractions();
			}
		},

		async mapa2_to_png(Obj) {
			var _this = this;
			var cnvs = await document.getElementById('map2');
			const _pol_typeSelect = this.pol_typeSelect;
			this.overlay = true;
			await html2canvas(cnvs).then(async function(canvas) {
				var imgData = await canvas.toDataURL("image/png");

				// Gravar no banco aqui
				Obj.imagem = imgData;
				let res_updateImovel = await _this.mapa_grava_imovel_poligono(Obj);

				if (res_updateImovel.status == 200) {
					_this.overlay = false;
					_this.vm_quadra_selected = _this.vm_quadra_options[_this.vm_quadra_selected.index+1]
				}
				else {
					_this.overlay = false;
					//console.log("ERRO AO ATUALIZAR: ", res_updateImovel)
				}
				
				if (_pol_typeSelect.value === 'Polygon' || _this.pol_typeSelect.value === 'Retangulo') {
					_this.pol_addInteractions();
				}

				// mapa_grava_imovel_poligono({acao: 'A', cod_imovel : cod_imovel, mapa_poligono_map: mapa_poligono_map, imagem: imgData}).
				// 	then((resultado) => {
				// 		console.log('resultado gravando :::: ', resultado);
				// });

			});

			this.$nextTick( ()=>{
				this.scrollToActiveItem()
			})
		},

		async scrollToActiveItem(Obj) {
      const activeItem = this.$el.querySelector('.v-list-item--active');
      if (activeItem) {
				this.$nextTick(() => {
					activeItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
				})
      }
    },

		getHeight() {
			return window.innerHeight - 64;
		},

		// transforma coordenadas
		transform(extent) {
			return transformExtent(extent, "EPSG:4326", "EPSG:3857");
		},

		rgba(p_cor) {
		  return "rgba(" +
						(p_cor = p_cor.replace("#", ""))
							.match(new RegExp("(.{" + p_cor.length / 3 + "})", "g"))
							.map(function (l) {
								return parseInt(p_cor.length % 2 ? l + l : l, 16);
							})
							.concat(0.5 || 1)
							.join(",") +
						")"
		},

// geometryFunction2(coordinates, geometry) {
// console.log("coordinates.length", coordinates.length)	
// 		// Pega primeiro ponto
// 		const center = coordinates[0];
// 		// Pega ultimo ponto
//         const last = coordinates[coordinates.length - 1];
		
//         const dx = center[0] - last[0];
//         const dy = center[1] - last[1];
//         const radius = Math.sqrt(dx * dx + dy * dy);
//         const rotation = Math.atan2(dy, dx);
//         const newCoordinates = [];
//         const numPoints = 4;
//         for (let i = 0; i < numPoints; ++i) {
//           const angle = rotation + (i * 2 * Math.PI) / numPoints;
//           //const fraction = i % 2 === 0 ? 1 : 0.5;
//           //const offsetX = radius * fraction * Math.cos(angle);
//           //const offsetY = radius * fraction * Math.sin(angle);
// 		  const offsetX = radius * Math.cos(angle);
//           const offsetY = radius * Math.sin(angle);
//           newCoordinates.push([last[0] + offsetX, last[1] + offsetY]);
//         }
//         newCoordinates.push(center.slice());
//         if (!geometry) {
// console.log("NEW", newCoordinates);
//           geometry = new Polygon([coordinates]);
//         } else {
// console.log("SET", newCoordinates);
//           geometry.setCoordinates([coordinates]);
//         }
// 					const coords = coordinates;
// 					const point1 = coords[0];
// 					const point2 = coords[1];
// 					const deltaY = (point2[1]) - (point1[1]);
// 					const deltaX = (point2[0]) - (point1[0]);
// 					return Math.atan2(deltaY, deltaX);
// },

		// Esta rotina, permite que o retnagulo não rotaione junto com o mapa na hora de desenha-lo
		geometryFunction(coordiantes, geometry) {

			if (!geometry) {
				geometry = new Polygon([coordiantes]);
			}
			var firstCorner = coordiantes[0];
			var thirdCorner = coordiantes[1];
			var currentViewRotation = this.map.getView().getRotation();
			var secondCorner = [thirdCorner[0], firstCorner[1]];
			var forthCorner = [firstCorner[0], thirdCorner[1]];
			if (currentViewRotation != 0) {
				var verticesToRotate = new MultiPoint([secondCorner, forthCorner]);
				var anchor = getCenter(verticesToRotate.getExtent());
				verticesToRotate.rotate(2 * currentViewRotation, anchor);
				secondCorner = verticesToRotate.getCoordinates()[0];
				forthCorner = verticesToRotate.getCoordinates()[1];
			}
			var newCoordinates = [firstCorner, secondCorner, thirdCorner, forthCorner, firstCorner];
			geometry.setCoordinates([newCoordinates]);
			return geometry;
		},

		// Poligonos --------------------------------------------------
		pol_addInteractions() {
			var _this = this;
			_this.map.removeInteraction(_this.pol_select);
			_this.map.removeInteraction(_this.pol_modify);
			_this.map.removeInteraction(_this.pol_draw);
			_this.map.removeInteraction(_this.pol_snap);
			//var currentViewRotation = _this.map.getView().getRotation();
			if (_this.pol_typeSelect.value == "Retangulo") {
			    //const geometryFunction = createBox();
				_this.pol_draw = new Draw({
					source: _this.pol_source1,
					type: 'Circle',
					geometryFunction: this.geometryFunction
				});
			}
			else {
				_this.pol_draw = new Draw({
					source: _this.pol_source1,
					type: _this.pol_typeSelect.value
				});
			}

			_this.pol_snap = new Snap({ source: _this.pol_source1 });
			_this.map.addInteraction(_this.pol_draw);
			
			_this.map.addInteraction(_this.pol_modify);
			_this.map.addInteraction(_this.pol_snap);

			_this.pol_vector1.getSource().once('addfeature', async function(e1) {
				if (!e1.feature.values_.lote) {
                    _this.grava_poligono(_this, e1.feature);

					// // Metodo Novo gravando por cod_imovel
					// const coord = e1.feature.getGeometry().getCoordinates();
					// e1.feature.setProperties({ 'cod_imovel': _this.vm_quadra_selected.cod_imovel, 'lote': _this.vm_quadra_selected.lote, 'quadra': _this.vm_quadra_selected.quadra, 'metragem': 'Metragem 999', 'cor': '#c0c0c0' });

					// // Usado para Foco de Zoom no Poligono  do Mapa Auxiliar --------------------------
					// const view = _this.map2.getView();
					// //var feature = e1.target.getFeatures().array_[0];
					// var feature = e1.feature;
					// var polygon = feature.getGeometry();
					// var poly = new Feature({
					// 	geometry: polygon,
					// }); 
					// var vectorSource1 = new VectorSource({
					// 	features: [poly],
					// });			
					// var vector_Aux = new VectorLayer({
					// 	source: vectorSource1,
					// 	style: new Style({
					// 		fill: new Fill({
					// 			color: 'rgba(0, 9, 255, 0.3)',
					// 		}),
					// 		stroke: new Stroke({
					// 			color: 'rgba(0, 9, 255, 1)',
					// 			width: 2
					// 		})
					// 	})
					// });			
					// await vector_Aux.set('name', 'vector_Aux');
					// await _this.map2.getLayers().forEach(function (layer) {
					// 	if (layer.get('name') != undefined && layer.get('name') === 'vector_Aux') {
					// 		_this.map2.removeLayer(layer);
					// 	}
					// });
					// await _this.map2.addLayer(vector_Aux);
					// _this.map2.getView().setRotation(_this.rotacao);
					// await view.fit(polygon, {padding: [130, 130, 130, 130]});
					// var ls_OBJ = {	acao				: 'A',
					// 				cod_imovel 			: e1.feature.values_.cod_imovel,
					// 				cod_empreendimento 	: parseInt(_this.id),
					// 				mapa_poligono_map	: JSON.stringify(coord),
					// 				imagem				: null}
					// _this.map2.once("rendercomplete", function (event) {
					// 	_this.mapa2_to_png(ls_OBJ);
					// });

				}
				else {
					//console.log(" Edição ===============", _this.vm_quadra_selected.cod_imovel)
				}
			});

		},

		// Selecoes ---------------------------------------------------
		sel_addInteractions() {
			var _this = this;
			//_this.map.once("rendercomplete", function () {});
			if (this.pol_select !== null) {
				this.map.removeInteraction(this.pol_select);
				this.map.removeInteraction(this.pol_modify);
				this.map.removeInteraction(this.pol_draw);
				this.map.removeInteraction(this.pol_snap);
			}
			const value = this.pol_selectElement.value;
			if (value === 'singleclick') {
				this.pol_select = this.pol_selectSingleClick;
			} else if (value === 'click') {
				this.pol_select = this.pol_selectClick;
			} else if (value === 'pointermove') {
				this.pol_select = this.pol_selectPointerMove;
			} else if (value === 'altclick') {
				this.pol_select = this.pol_selectAltClick;
			} else {
				this.pol_select = null;
			}
			if (this.pol_select !== null) {
				const _pol_typeSelect = this.pol_typeSelect;			
				this.map.addInteraction(this.pol_select);
				this.pol_select.on('select', async (e) => {

					if (e.selected.length > 0) {
						const coord = e.target.getFeatures().array_[0].getGeometry().getCoordinates();
						//console.log(JSON.stringify(coord));
						// console.log(' cod_imovel: ' + e.target.getFeatures().array_[0].values_.cod_imovel +
						//             ' Lote: ' + e.target.getFeatures().array_[0].values_.lote +
						//             ' Quadra: ' + e.target.getFeatures().array_[0].values_.quadra +
						//             ' Metragem: ' + e.target.getFeatures().array_[0].values_.metragem +
						//             ' Coords: ' + JSON.stringify(coord));
						//console.log("update imovel set mapa_poligono_map='" + JSON.stringify(coord) +
						//            "' where cod_empreendimento=" +  this.mapEmp.routeParams.mapa  + " and quadra='" + e.target.getFeatures().array_[0].values_.quadra + "' and lote='" + e.target.getFeatures().array_[0].values_.lote + "';");
						if (_pol_typeSelect.value === 'Mapa') {
							//console.log("imagem", e.selected[0])
							_this.grava_poligono(_this, e.selected[0]);
						}
					}
					// document.getElementById('status').innerHTML = '&nbsp;' +
					// 	e.target.getFeatures().getLength() +
					// 	' selected features (last operation selected ' + e.selected.length +
					// 	' and deselected ' + e.deselected.length + ' features)';
				});
			}
		},

		async grava_Poligono(_this, p_feature) {
			// Metodo Novo gravando por cod_imovel
			const coord = p_feature.getGeometry().getCoordinates();
			p_feature.setProperties({ 'cod_imovel': _this.vm_quadra_selected.cod_imovel, 'lote': _this.vm_quadra_selected.lote, 'quadra': _this.vm_quadra_selected.quadra, 'metragem': 'Metragem 999', 'cor': '#c0c0c0' });

			// Usado para Foco de Zoom no Poligono  do Mapa Auxiliar --------------------------
//			const view = _this.map2.getView();
			//var feature = e1.target.getFeatures().array_[0];
			var feature = p_feature;
			var polygon = feature.getGeometry();
			var poly = new Feature({
				geometry: polygon,
			}); 
			var vectorSource1 = new VectorSource({
				features: [poly],
			});			
			var vector_Aux = new VectorLayer({
				source: vectorSource1,
				style: new Style({
					fill: new Fill({
						color: 'rgba(0, 9, 255, 0.3)',
					}),
					stroke: new Stroke({
						color: 'rgba(0, 9, 255, 1)',
						width: 2
					})
				})
			});			
			await vector_Aux.set('name', 'vector_Aux');
			await _this.map2.getLayers().forEach(async function (layer) {
				if (layer.get('name') != undefined && layer.get('name') === 'vector_Aux') {
					if (layer) {
						await _this.map2.removeLayer(layer);
					}
				}
			});
			await _this.map2.addLayer(vector_Aux);
			_this.map2.getView().setRotation(_this.rotacao);
			const view = _this.map2.getView();
			await view.fit(polygon, {padding: [130, 130, 130, 130]});
			// Fernando, 2023-07-13, este zoom fica mais distante para possivelmente servir como imagem do Chico para a vistoria
			// await view.fit(polygon, {padding: [130, 130, 130, 130]});

			var ls_OBJ = {	acao				: 'A',
							cod_imovel 			: p_feature.values_.cod_imovel,
							cod_empreendimento 	: parseInt(_this.id),
							mapa_poligono_map	: JSON.stringify(coord),
							imagem				: null}
			await _this.map2.renderSync();
			_this.mapa2_to_png(ls_OBJ);

//			_this.map2.once("rendercomplete", async function (event) {
//console.log('rendercomplete----', ls_OBJ);
//				await _this.mapa2_to_png(ls_OBJ);
//			});

		}

	},

}

</script>

<style scoped>
#map {
  position: relative;
}
#map2 {
  width: 350px;
  height: 350px;
  position: absolute;
  top: 0;
  left: 0;
}
#map2 {
  	z-index: 0;
	/* display:none; */
}

.v-list-item__content{
	padding: 0;
}
.v-list-item{
	min-height: 0;
	padding: 0 8px;
	height: 22px;
}

select {
  text-align: center;
  margin: 0 auto;
  display: block;
	background-color: #E3EEF9; 
	width: 100%; 
	font-size: 1rem; 
	color: #1976d2
}
</style>